body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Coconat'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.marginAuto {
  width: 50% !important;
  margin: auto !important;
  text-align: center !important;
  margin-left: 34% !important;
}
.widthMobile p {
  margin-left: 1rem!important;
}

.bookBackground{
  /* height: 100vh; */
  background-color: black;
}
.confirmBackground {
  height: auto;
  background-color: black;
}
.brideBackground{
  padding-top: 5.5rem;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
}

.image2 {
  position: absolute;
  left: 0px;
  border: 1px solid #fff;
}
.img3{
    position: absolute;
    left: 150px;
    top: -60px;
}
.text-title{
  font: normal normal normal 45px/54px Montserrat;
  /*  */
    position: absolute;
    left: 10%;
    top: 110px;
    color: #fff;
}
.text{
    font: normal normal normal 18px/22px Montserrat;
    position: absolute;
    left: 10%;
    top: 180px;
    color: #fff;
    max-width: 50rem;
}

.bride-cover {
  max-width: 100vw;
}

.pattern {
  max-width: 100vw;
}
.paternDiv {
  height: 25vh !important;
}
.bri{
  width: 100%;
}
.z-index2 {
  background-color: #fff;
  z-index: 2;
  top: 0;
  left: 0;
  border-radius: 20px;
  height: 99rem !important;
  }
.request{
  font: normal normal normal 18px/22px Montserrat;
}
.center {
  height: 100vh;
  position: relative;
  padding-inline: 8%;
}
.form-titles{
  font: normal normal 600 12px/15px Montserrat;
  color: #D0C5B4;
}
.position-modal{
  margin-top: 16rem;
  margin-left: 10% !important;
  max-width: 80% !important;
  z-index: 2 ;
}

.modal-header{
  margin-top: 3rem;
}
.title{
  font: normal normal normal 18px/22px Montserrat;
  /*  */
}
.btn-request{
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: none !important;
  font: normal normal 600 14px/21px Poppins !important;
  padding-inline: 3% !important;
  height: 45px;
}
.text-confirm{
  font: normal normal normal 14px/18px Montserrat;
  width: 70% !important;
}

::placeholder {
font: normal normal normal 16px/19px Montserrat;
  color: #818181 ;
}

.header{
  border-bottom: 0.5px solid #fff;
  padding-top: 5rem;
}
footer{
  margin-top: -5rem;
}
.margin-top{
  margin-top: -4rem;
  margin-left: 150px; 
}
.calender{
  width: 42rem;
  margin-left: 5%;
}
.confirm-title{
font: normal normal normal 35px/42px Montserrat;
color: #fff;
text-align: center !important;
/*  */
}
.confirm-text{
  color: #fff;
  font: normal normal normal 18px/22px Montserrat;
  text-align: center !important;
}

.border-date{
  border: 1px solid #fff !important;
  border-radius: 10px;
  width: 20rem;
  margin: auto !important;
}
.bride3{
  margin-top: -6rem;
  margin-left: 5rem;
}
.margin-left{
  /* display: flex !important; */
  padding-left: 10rem;
}

.margintop{
  margin-top: -5rem;
}
.bride-mobile {
  display: none;
}
.layerMobile {
  display: none;
}
.section-display {
  display: flex !important;
}
.widthDiv{
 width: 50% !important;
}
.firstImg {
width: 40%;
margin-top: 4rem !important;
}

.secondImg {
width: 40%;
margin-top: -3rem !important;
}

.bride3 {
width: 40%;
} 

.error {
  border:2px solid red;
  outline: none;
}

.label-error {
  color:red;
  font-size:13px;
}

.label-success {
  color:darkgreen;
  font-size:13px;
}

.outer-wrapper {
  max-width: 100vw;
  overflow-x: scroll;
  position: relative;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.pseduo-track {
  background-color: #f1db9d;
  height: 2px;
  width: 100%;
  position: relative;
  top: -3px;
  z-index: -10;
}

@media (any-hover: none) {
  .pseduo-track {
    display: none;
  }
}

.outer-wrapper::-webkit-scrollbar {
  height: 5px;
}

.outer-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.outer-wrapper::-webkit-scrollbar-thumb {
  height: 20px;
  background-color: #CCC;
}

.outer-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #818181;
}

.outer-wrapper::-webkit-scrollbar:vertical {
  display: none;
}

.inner-wrapper {
  display: flex;
  padding-bottom: 10px;
}

.pseudo-item {
  height: 300px;
  width: 369px;
  margin-right: 59px;
  flex-shrink: 0;
  background-color: gray;
}

.pseudo-item:nth-of-type(2n) {
  background-color: lightgray;
}
.handIcon {
  text-align: center;
  display:none;
}

@media (max-width:1393px) {
  .handIcon {
    display:block;
  }
}
@media (max-width:1044px) {
  .outer-wrapper{
    height: 40vw;
  }
}

@media (max-width: 1024px) {
.outer-wrapper {
    height: 26.5rem;
}
}
@media (max-width:1045px) {

 /* .maxWidth {
    max-width: 40rem !important;
    max-height: 20rem !important;
  }*/
  

  .overFlow {
    /*overflow-x: scroll !important;
    overflow-y: hidden !important;*/
  }

  .widthSection {
    height: 30rem;
  }
}

@media (max-width:1050px) {
  .paternDiv {
    height: 70vh !important;
  }
}


@media (max-width:995px) {
  .btn-request {
    font-size: 10px !important;
  }

  .paternDiv {
    height: 75vh !important;
  }
}

@media (max-width:780px) {


  .paternDiv {
    height: 125vh !important;
  }

  .btn-request {
    font-size: 8px !important;
  }

  /*.outer-wrapper {
    height: 19rem;
}*/

}



@media (max-width:560px) {
  .z-index2 {
    height: 260vh !important;
  }

  .paternDiv {
    height: 180vh !important;
  }
}

@media (max-width:1220px) {

  .margintop {
    margin-top: 10rem;
  }
}

@media (max-width:800px) {
  .section-display {
    display: block !important;
  }

  .calender {
    margin: auto;
  }

  .widthDiv {
    margin-top: 5rem;
    margin-left: 13rem;
    margin-bottom: -5rem;
  }

  .heightConfirm {
    height: 12vh !important;
  }

}

@media (max-width:525px){
  .text-title {
    top:55px;
  }
  .text{
    top:160px;
  }
}
@media (max-width:435px){
  .request {
      padding-top: 1rem;
    }
  .TimeBtn{
    width: 2rem !important;
  }
  .z-index2 {
      height: 82rem !important;
    }
  .widthDiv{
    margin-left: 5rem !important;
  }

.img3{
  max-width: 76px;
  left: 30px;
  top: -37px;
}
.margin-top{
    max-width: 76px;
    top: 105px;
    left: -120px;
}

.image1{
  display: none;
}
.image2{
  display: none; 
}
  .text-title{
  font-size: 24px;
  left: 30px;
  top: 40px;
}
.bride-mobile{
  display: block;
  position: relative;
  width: 100% ;
}
.layerMobile{
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
}
.text{
  font-size: 16px;
  left: 30px;
  top: 100px;
  right: 5%;
}
.position-modal{
    margin-left: 10% !important;
    margin-inline: 8%;
    margin-top:13rem;
}
/* .bookBackground{
  height: 200vh;
} */

.atcb-checkmark {
  display: none!important;
}
span.atcb-icon-apple {
  line-height: 1rem!important;
}
.section-display{
  display: block !important;
}
.text-confirm{
  width: 100% !important;
}
.btn-request{
    width: 100% !important;
}

.calender {
  width: fit-content;
margin: auto;
}
.confirm-title{
  font-size: 25px;
}

.confirmBackground{
  /*height: 180vh !important;*/
}
.firstImg{
  position: relative;
  width: 80% !important;
  margin-top: 0px !important;
}
.secondImg{
  width: 40% !important;
  position: absolute;
  margin-left: -2rem;
  margin-top: 2rem !important;
}
.bride3{
  margin-top: 10rem;
  position: absolute;
    width: 40% !important;
    left: 4rem !important;
}
.margin-left{
 padding-left: 0rem !important;
margin-top: 4rem;
margin-left: 2.5rem !important;
}
.bride-cover{
  margin-top: 8rem;
  /* height: 10rem; */
}
.bride-cover{
  margin-top: 7rem;
}
.marginAuto {
  margin-left: 0% !important;
  margin :0px !important;
  margin-left: 1rem !important;
}
.calenderText {
  font: normal normal 600 16px/25px Poppins !important;
}
.font-days {
  font: normal normal bold 12px/19px Montserrat !important;
  max-width: 70%;
}
.timeBtn{
  /*max-width: 55%;*/
    font: normal normal normal 8px/8px Montserrat !important;
    height: 1.3rem !important;
}
.widthMobile{
  width: 25% !important;
}
.widthSection {
  height: 23rem;
}
}

.calenderText{
  font: normal normal 600 23px/34px Poppins;
  align-items: center !important;
}

.font-days{
  font: normal normal bold 16px/19px Montserrat;
}
.font-daysDisabled {
  font: normal normal bold 16px/19px Montserrat;
  color: #B1B1B1;
}
.timeBtn{
border: 1px solid #DDDDDD;
  border-radius: 10px;
  width: 5rem;
  height: 45px;
  background-color: #fff;
  font: normal normal normal 16px/19px Montserrat;
  color: #131313;
  margin-left: 4px;
}
.timeBtn.active {
  border: 1px solid #63666A;
  background-color: #eae9e9 !important;
  font-weight: bold;
  }
button:disabled{
  color: #B1B1B1;
  background-color: #F8F8F8;
  border: none;
}
.swipeBtn{
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 434px) {
.outer-wrapper {
  height: 19.5rem;
}
.handIcon {
  font-size:11px;
}
.handIcon img {
  width:25px;
  height:25px;
}
}

@media (max-width: 370px) {
  .outer-wrapper {
    height: 19.5rem;
}
}
